import 'svelte/internal';
export function makeBrush(params) {
    const container = document.createElement('div');
    const highlightsDiv = document.createElement('div');
    const textarea = document.createElement('textarea');
    // Style the div to display static text like the textarea
    highlightsDiv.style.whiteSpace = 'pre';
    highlightsDiv.style.fontFamily = params.font || 'monospace';
    // Style the divs so that it grows and shrinks to the size of the text
    highlightsDiv.style.width = 'fit-content';
    container.style.width = 'fit-content';
    // Keep the textarea from looking odd
    textarea.style.resize = 'none';
    textarea.style.overflow = 'hidden';
    textarea.style.border = 'none';
    textarea.style.outline = 'none';
    textarea.style.margin = 'none';
    textarea.style.padding = '0';
    textarea.style.whiteSpace = 'pre';
    textarea.style.fontFamily = params.font || 'monospace';
    textarea.spellcheck = false;
    // Make the text, but not the caret, of the textarea transparent
    textarea.style.color = 'transparent';
    textarea.style.background = 'transparent';
    textarea.style.caretColor = params.caretColor || 'black';
    // position the textarea so that it takes up all the space in it's parent
    textarea.style.position = 'absolute';
    textarea.style.top = '0';
    textarea.style.right = '0';
    textarea.style.bottom = '0';
    textarea.style.left = '0';
    // make the highlights and textarea the only children of the container
    container.style.position = 'relative';
    container.appendChild(highlightsDiv);
    container.appendChild(textarea);
    // Set the text of both the textarea and the highlights div to the same content
    textarea.value = params.initialText;
    highlightsDiv.innerHTML = highlight(textarea.value);
    // Append the container to the parent
    params.element.appendChild(container);
    // Anytime the text in the textarea changes, sync it to the highlights div
    const unsubscribe = (() => {
        const inputHandler = () => {
            params.onChange(textarea.value);
            highlightsDiv.innerHTML = highlight(textarea.value);
        };
        textarea.addEventListener('input', inputHandler);
        const keydownHandler = (event) => {
            if (event.key !== 'Tab')
                return;
            event.preventDefault();
            const start = event.currentTarget.selectionStart;
            const end = event.currentTarget.selectionEnd;
            // set textarea value to: text before caret + tab + text after caret
            event.currentTarget.value = event.currentTarget.value.substring(0, start) + '\t' + event.currentTarget.value.substring(end);
            // put caret at right position again
            event.currentTarget.selectionStart = event.currentTarget.selectionEnd = start + 1;
            inputHandler();
        };
        textarea.addEventListener('keydown', keydownHandler);
        return () => {
            textarea.removeEventListener('input', inputHandler);
            textarea.removeEventListener('keydown', keydownHandler);
        };
    })();
    function highlight(value) {
        const highlights = [];
        params.highlight(value, (loc, style) => highlights.push({ loc, style, text: value.slice(...loc) }));
        // Here we should have all the highlights...
        // Sort them by their start index - least to greatest
        const sorted = highlights.sort((a, b) => a.loc[0] - b.loc[0]);
        const normalized = normalizeHighlights(sorted, value);
        // ... compile them and return them
        return compileHighlights(normalized);
    }
    function normalizeHighlights(highlights, value) {
        let currentIndex = 0;
        const allHighlights = [];
        for (const highlight of highlights) {
            const [start, end] = highlight.loc;
            if (start < currentIndex)
                throw new Error('Highlights cannot overlap each other');
            // If there is empty space between highlights, fill it with the default style
            if (start > currentIndex)
                allHighlights.push({
                    loc: [currentIndex, start],
                    style: params.defaultStyle,
                    text: value.slice(currentIndex, start)
                });
            allHighlights.push(highlight);
            currentIndex = end;
        }
        if (currentIndex < value.length)
            allHighlights.push({
                loc: [currentIndex, value.length],
                style: params.defaultStyle,
                text: value.slice(currentIndex, value.length)
            });
        return allHighlights;
    }
    function compileHighlights(highlights) {
        return highlights.map(compileHighlight).join('');
    }
    function compileHighlight(highlight) {
        const style = stringifyHighlightStyle(highlight.style);
        const text = sterilizeText(highlight.text);
        return `<span style="${style}">${text}</span>`;
    }
    function stringifyHighlightStyle(style) {
        let strungStyle = ``;
        if (style.color)
            strungStyle += `color: ${style.color};`;
        if (style.background)
            strungStyle += `background: ${style.background};`;
        if (style.ringColor)
            strungStyle += `outline-color: ${style.ringColor};`;
        if (style.ringWidth)
            strungStyle += `outline-width: ${style.ringWidth}px;`;
        if (style.roundCornersBy)
            strungStyle += `border-radius: ${style.roundCornersBy}px`;
        return strungStyle;
    }
    function sterilizeText(text) {
        return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }
    return {
        destroy() {
            unsubscribe();
        }
    };
}
