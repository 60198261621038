import { get } from 'svelte/store';
import { db } from './db';
import { v4 } from '@lukeed/uuid';
export function addCommas(number) {
    if (number.length <= 3)
        return number;
    const extra = number.length - 3;
    return `${addCommas(number.slice(0, extra))},${number.slice(extra)}`;
}
export function addCommasToDecimal(number) {
    const [wholeNumber, cents] = number.split('.');
    return `${addCommas(wholeNumber)}.${cents}`;
}
export function formatMoney(money) {
    if (money < 0)
        return `- $${addCommasToDecimal((money * -1).toFixed(2))}`;
    return `$${addCommasToDecimal(money.toFixed(2))}`;
}
export function compileEnvelopeTransactions(envelopeId, $db = get(db)) {
    const compiledTransactions = [];
    // Keep track of the current balance as we walk down
    let currentBalance = $db.envelopes[envelopeId].balance;
    const transactions = Object.values($db.transactions).sort((a, b) => b.date - a.date);
    for (const transaction of transactions) {
        // Skip all transactions that have nothing to do with this envelope
        if (!transaction.envelopes[envelopeId])
            continue;
        const amount = transaction.envelopes[envelopeId];
        compiledTransactions.push({
            type: transaction.type,
            amount,
            label: transaction.label,
            transactionId: transaction.id,
            balanceAt: currentBalance,
            date: transaction.date
        });
        if (transaction.type === 'income')
            currentBalance -= amount;
        else
            currentBalance += amount;
    }
    return compiledTransactions;
}
export function clickOutside(node, { enabled, cb }) {
    const handleOutsideClick = ({ target }) => {
        if (!node.contains(target)) {
            if (cb)
                cb();
        }
    };
    function update(enabled) {
        if (enabled) {
            window.addEventListener('click', handleOutsideClick);
        }
        else {
            window.removeEventListener('click', handleOutsideClick);
        }
    }
    update(enabled || true);
    return {
        update,
        destroy() {
            window.removeEventListener('click', handleOutsideClick);
        }
    };
}
export function onEnter(node, { enabled, cb }) {
    function handler(event) {
        if (event.key !== 'Enter')
            return;
        event.preventDefault();
        if (cb)
            cb();
    }
    function update(enabled) {
        if (enabled) {
            node.addEventListener('keydown', handler);
        }
        else {
            node.removeEventListener('keydown', handler);
        }
    }
    update(enabled || true);
    return {
        update,
        destroy() {
            window.removeEventListener('keyup', handler);
        }
    };
}
export function autofocus(node) {
    node.focus();
    return {};
}
export function getAllocatedAmount(envelopes) {
    let amount = 0;
    for (const id in envelopes) {
        const envelopeAmount = envelopes[id];
        amount += envelopeAmount;
    }
    return amount;
}
export function allEnvelopesExist(envelopes) {
    for (const id in envelopes) {
        if (!get(db).envelopes[id])
            return false;
    }
    return true;
}
export function pickExpenseEnvelope(alreadySelected) {
    let first;
    for (const id in get(db).envelopes) {
        first = id;
        if (!alreadySelected[id])
            return id;
    }
    if (!first)
        throw new Error('No envelopes have been created');
    return first;
}
export function envelopesLeft(alreadySelected, $db = get(db)) {
    const left = [];
    for (const id in $db.envelopes) {
        if (!alreadySelected[id])
            left.push($db.envelopes[id]);
    }
    return left;
}
export function calculateEnvelopeBalance(envelopeId, $db = get(db)) {
    let balance = $db.envelopes[envelopeId].startingBalance;
    for (const id in $db.transactions) {
        const transaction = $db.transactions[id];
        if (!transaction.envelopes[envelopeId])
            continue;
        const amount = transaction.envelopes[envelopeId];
        if (transaction.type === 'income')
            balance += amount;
        else
            balance -= amount;
    }
    return balance;
}
export function formatDate(ms) {
    const date = new Date(ms);
    return `${date.getMonth() + 1}/${date.getDate()}/${String(date.getFullYear()).slice(2)}`;
}
export function getUserTotalBalance($db = get(db)) {
    let balance = 0;
    for (const id in $db.envelopes) {
        balance += $db.envelopes[id].balance;
    }
    return balance;
}
export function randomUUID() {
    return v4();
}
export function makeEncryptor(key) {
    const textToChars = (text) => text.split('').map(c => c.charCodeAt(0));
    const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
    const applyKeyToChar = (code) => textToChars(key).reduce((a, b) => a ^ b, code);
    function decrypt(encoded) {
        return (encoded.match(/.{1,2}/g) || [])
            .map(hex => parseInt(hex, 16))
            .map(applyKeyToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }
    function encrypt(text) {
        return textToChars(text).map(applyKeyToChar).map(byteHex).join('');
    }
    return { encrypt, decrypt };
}
export function applyPercentage(number, percent) {
    return number * percent * 0.01;
}
export function resolveIncomeType(amount, envelopes, incomeType) {
    let amountLeft = amount;
    const addAmount = (envelopeId, amount) => {
        if (envelopes[envelopeId])
            envelopes[envelopeId] += amount;
        else
            envelopes[envelopeId] = amount;
    };
    for (const operation of incomeType.operations) {
        if (operation.operation === 'fixed-take') {
            amountLeft -= operation.amount;
            addAmount(operation.envelopeId, operation.amount);
        }
        else if (operation.operation === 'variable-take') {
            let amountToTake = applyPercentage(amountLeft, operation.percentage);
            if (operation.minAmount && amountToTake < operation.minAmount)
                amountToTake = operation.minAmount;
            if (operation.maxAmount && amountToTake > operation.maxAmount)
                amountToTake = operation.maxAmount;
            amountLeft -= amountToTake;
            addAmount(operation.envelopeId, amountToTake);
        }
        else if (operation.operation === 'split') {
            let amountAtStartOfOperation = amountLeft;
            for (const envelopeId in operation.envelopePercentages) {
                const percent = operation.envelopePercentages[envelopeId];
                const amountToTake = applyPercentage(amountAtStartOfOperation, percent);
                amountLeft -= amountToTake;
                addAmount(envelopeId, amountToTake);
            }
        }
    }
    return {
        amountLeft,
        envelopes
    };
}
