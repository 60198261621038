import { writable } from 'svelte/store';
export const currentToasts = writable([]);
export const toast = async (params) => {
    const close = () => currentToasts.update(toasts => {
        toasts.splice(toasts.indexOf(toast), 1);
        return toasts;
    });
    const toast = {
        message: params.message,
        type: params.type || 'success',
        timeout: params.timeout || 3000,
        close: close
    };
    currentToasts.update(toasts => [toast, ...toasts]);
    await setTimeout(() => close, toast.timeout);
};
